<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon 
        v-if="$store.state.userModule.isAuthenticated" 
        @click.stop="drawer = !drawer" 
      />

      <div class="d-flex align-center">
        Verwaltungsoberfläche der Wahlarzt-Vertreterliste der {{ hospitalName }} 
      </div>

      <template v-if="$store.state.userModule.isAuthenticated">
        <v-spacer />

        <div class="align-center d-none d-md-flex">
          <a href="#" @click.prevent="logout()">Abmelden</a>
        </div>
      </template>

    </v-app-bar>

    <v-navigation-drawer 
      v-if="$store.state.userModule.isAuthenticated"
      absolute 
      app 
      v-model="drawer"
    >
        <v-toolbar flat dark color="primary"> </v-toolbar>

        <v-list dense v-if="!$store.state.userModule.role.isExternal">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><b>Vertretungsliste</b></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/fachbereiche">
            <v-list-item-icon>
              <v-icon>local_hospital</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Fachbereiche</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense v-if="$store.state.userModule.role.isVerwaltungOrAdmin">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><b>Freigaben</b></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/freigabe-anfragen">
            <v-list-item-icon>
              <v-icon>format_list_numbered</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Offene Anfragen
                <v-chip
                  v-if="$store.state.freigabenModule.openRequestCount > 0"
                  class="ma-2"
                  color="green"
                  text-color="white"
                  x-small
                >
                  {{ $store.state.freigabenModule.openRequestCount }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/freigaben-changelog">
            <v-list-item-icon>
              <v-icon>checklist_rtl</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Protokoll</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><b>Archiv</b></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/archiv">
            <v-list-item-icon>
              <v-icon>calendar_month</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Individueller Zeitraum</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/downloads">
            <v-list-item-icon>
              <v-icon>archive</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Jahresarchive</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-4">
            <v-btn block color="primary" @click="logout()">
              Abmelden
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false
  }),
  computed: {
    hospitalName() {
      return process.env.VUE_APP_HOSPITAL_NAME
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('userModule/logout')
    }
  },
  mounted () {
    this.$store.watch((state) => state.userModule.isAuthenticated, (newProps) => {
        if (newProps) {
          this.$store.dispatch('userModule/getCurrentUser')
        }
    })

    this.$store.watch((state) => state.userModule.role.isVerwaltungOrAdmin, (newProps) => {
        if (newProps) {
          this.$store.dispatch('freigabenModule/getOpenRequestCount')
        }
    })

    setTimeout(() => {
      this.drawer = this.$vuetify.breakpoint.mdAndUp
      this.$store.dispatch('userModule/checkLogin')
    }, 250)
  }
}
</script>

<style scoped>
header a {
  color: #fff;
}
</style>

<style>
.v-main__wrap {
  max-height: calc(100vh - 64px);
  overflow-x: scroll;
}
</style>
