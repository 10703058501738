import axios from 'axios'

axios.interceptors.request.use(
    async (config) => {
        if (localStorage.getItem('jwt') !== null) {
            // if the token expires within the next 30 seconds, refresh it
            // const timeLeft = parseInt(localStorage.getItem('jwt_expires')) - Date.now()
            // if (!config.url.includes('refresh') && timeLeft < localStorage.getItem('jwt_lifetime') - 30000) {
            //     console.log('refreshing token')
            //     const res = await axios.post(
            //         `${DIRECTUS_BASE_URL}/auth/refresh`,
            //         {
            //             refresh_token: localStorage.getItem('refresh_token'),
            //             mode: 'json'
            //         }
            //     )
            //     console.log('refreshed token', res)
            //     if (res.status === 200) {
            //         localStorage.setItem('jwt', res.data.data.access_token)
            //         localStorage.setItem('jwt_expires', (new Date().getTime() + res.data.data.expires))
            //         localStorage.setItem('jwt_lifetime', res.data.data.expires)
            //         localStorage.setItem('refresh_token', res.data.data.refresh_token)
            //     } else {
            //         localStorage.removeItem('jwt')
            //         localStorage.removeItem('jwt_expires')
            //         localStorage.removeItem('jwt_lifetime')
            //         localStorage.removeItem('refresh_token')
            //         location.assign('/login')
            //     }
            // }
                
            config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`
        } else {
            delete config.headers.Authorization
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

const DIRECTUS_BASE_URL = process.env.VUE_APP_DIRECTUS_BASE_URL

/**
 * Sends a POST request to log the user into the Directus system.
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const login = (email, password) =>
    axios.post(
        `${DIRECTUS_BASE_URL}/auth/login`,
        {
            email: email,
            password: password
        }
    )

/**
 * Sends a POST request to refresh the user's JWT token.
 * 
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const refreshToken = () =>
    axios.post(
        `${DIRECTUS_BASE_URL}/auth/refresh`,
        {
            refresh_token: localStorage.getItem('refresh_token'),
            mode: 'json'
        }
    )

/**
 * Sends a POST request to log the user out from the Directus system.
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const logout = (refresh_token) =>
    axios.post(
        `${DIRECTUS_BASE_URL}/auth/logout`,
        {
            refresh_token: refresh_token
        }
    )

/**
 * Sends a GET request to get the current user profile.
 * 
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCurrentUser = () =>
    axios.get(`${DIRECTUS_BASE_URL}/users/me`)

/**
 * Sends a GET request to get the PDF archive list
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getArchive = (queryParams = { fields: 'date,filename' }) =>
    axios.get(
        `${DIRECTUS_BASE_URL}/items/Archiv`, 
        {
            params: queryParams
        }
    )


/**
 * Sends a GET request to get the PDF archive list
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getYearlyArchives = (queryParams = { fields: 'year,filename' }) =>
 axios.get(
     `${DIRECTUS_BASE_URL}/items/Jahresarchive`, 
     {
         params: queryParams
     }
 )

/**
 * Sends a GET request to get the PDF archive list
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getDownloadFile = (filename) =>
    axios.get(
        `${DIRECTUS_BASE_URL}/files`,
        {
            params: {
                'filter[filename_download]': filename
            }
        }
    )

/**
 * Sends a GET request to get all Fachbereiche
 *
 * @param {Object} queryParams - Query parameters
 * @param {string} queryParams.fields - Fields to return
 * @param {string} queryParams.sort - Sort order
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFachbereiche = (queryParams) =>
    axios.get(
        `${DIRECTUS_BASE_URL}/items/Fachbereiche`,
        {
            params: queryParams
        }
    )

/**
 * Sends a GET request to get a single Fachbereich by ID
 * 
 * @param {string} id - Fachbereich ID
 * @param {Object} queryParams - Query parameters
 * @param {string} queryParams.fields - Fields to return
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFachbereich = (id, queryParams) =>
    axios.get(
        `${DIRECTUS_BASE_URL}/items/Fachbereiche/${id}`,
        {
            params: queryParams
        }
    )

/**
 * Sends a GET request to get all Vertretungen
 * 
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getVertretungen = (queryParams) =>
    axios.get(
        `${DIRECTUS_BASE_URL}/items/vertretungen`,
        {
            params: queryParams
        }
    )

/**
 * Sends a POST request to create a new Vertretung
 * @param {Object} payload - Vertretung data
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const addVertretung = (payload) =>
    axios.post(
        `${DIRECTUS_BASE_URL}/items/vertretungen`,
        payload
    )

/**
 * Sends a PATCH request to update a Vertretung
 * @param {string} id - Vertretung ID
 * @param {Object} payload - Vertretung data
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const patchVertretungById = (id, payload) =>
    axios.patch(
        `${DIRECTUS_BASE_URL}/items/vertretungen/${id}`,
        payload
    )