export default {
    isAuthenticated: false,
    authError: false,
    user: null,
    role: {
        id: null,
        isExternal: true,
        isFachklinik: false,
        isVerwaltungOrAdmin: false
    }
}
