import * as directus from '@/services/directus.js'

export function checkLogin ({ commit }) {
    if (localStorage.getItem('jwt') !== null) {
        commit('LOGIN_SUCCESS')
    }
}

export function login ({ commit }, data) {
    commit('LOGIN')

    directus.login(data.username, data.password)
        .then(response => {
            console.log(response)
            if (response.status === 200 && response.data?.data?.access_token?.length) {
                localStorage.setItem('jwt', response.data.data.access_token)
                localStorage.setItem('jwt_expires', (new Date().getTime() + response.data.data.expires))
                localStorage.setItem('jwt_lifetime', response.data.data.expires)
                localStorage.setItem('refresh_token', response.data.data.refresh_token)
                commit('LOGIN_SUCCESS')
            } else {
                commit('LOGIN_ERROR')
            }
        }).catch(error => {
            console.log(error)
            commit('LOGIN_ERROR')
        })
}

export function logout ({ commit }) {
    const doLogout = () => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('jwt_expires')
        localStorage.removeItem('jwt_lifetime')
        localStorage.removeItem('refresh_token')
        clearInterval(window.tokenInterval)
        commit('LOGOUT')
        location.assign('/login')
    }

    const refresh_token = localStorage.getItem('refresh_token')
    if (refresh_token !== null) {
        directus.logout(refresh_token)
            .then(doLogout)
            .catch(error => {
                console.log(error)
                doLogout()
            })
    } else {
        doLogout()
    }
}

export function getCurrentUser ({ commit }) {
    directus.getCurrentUser()
        .then(response => {
            if (response.status === 200) {
                const roleMapping = {
                    "983cd148-0532-433f-8e6f-8732008f657d": "admin",
                    "842b7696-47e7-45dc-bd6d-c7c8a450b69b": "verwaltung",
                    "6ab3dcf2-5470-4c8d-9c0d-5a0bfada4aa5": "extern"
                }

                commit('GET_USER_SUCCESS', {
                    user: response.data.data,
                    role: {
                        id: response.data.data.role,
                        isExternal: roleMapping[response.data.data.role] === 'extern',
                        isFachklinik: roleMapping[response.data.data.role] !== 'extern' && roleMapping[response.data.data.role] !== 'admin' && roleMapping[response.data.data.role] !== 'verwaltung',
                        isVerwaltungOrAdmin: roleMapping[response.data.data.role] === 'verwaltung' || roleMapping[response.data.data.role] === 'admin',
                    }
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
}