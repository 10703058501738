import * as directus from '@/services/directus.js'
import { DateTime } from 'luxon'

export function getOpenRequestCount({ commit }) {
    let futureVertretungen = []

    let params = {
        fields: ['*.*'].join(','),
        limit: 1000,
        filter: {
            status: {
                _eq: 0
            }
        }
    }

    directus.getVertretungen(params)
        .then(response => {
            if (response.status === 200 && response.data?.data) {
                if (response.data?.data?.length >= 1) {
                    futureVertretungen = response.data.data.filter(vertretung => {
                        return DateTime.fromISO(vertretung.gueltig_ab) > DateTime.now()
                    })
                }
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            commit('GET_OPEN_REQUESTS_COUNT_SUCCESS', futureVertretungen.length)
        })
}
