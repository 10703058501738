import Vue from 'vue'
import Vuex from 'vuex'

import freigabenModule from './module-freigaben'
import userModule from './module-user'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        freigabenModule,
        userModule
    }
})
