export function LOGIN(state) {
    state.authError = false
}

export function LOGIN_SUCCESS (state) {
    state.isAuthenticated = true
}

export function LOGIN_ERROR(state) {
    state.authError = true
}

export function LOGOUT(state) {
    state.isAuthenticated = false
}

export function GET_USER_SUCCESS(state, payload) {
    state.user = payload.user
    state.role = payload.role
}